import { render, staticRenderFns } from "./UploadImgs.vue?vue&type=template&id=7d5cdaac&scoped=true&"
import script from "./UploadImgs.vue?vue&type=script&lang=js&"
export * from "./UploadImgs.vue?vue&type=script&lang=js&"
import style0 from "./UploadImgs.vue?vue&type=style&index=0&id=7d5cdaac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d5cdaac",
  null
  
)

export default component.exports